import request from '@/utils/request'

export function getList(data) {
  return request({
    url: '/ybtWeb/queryProduct',
    method: 'get',
    params: data
  })
} 

export function getProductDetail(data) {
  return request({
    url: '/ybtWeb/productDetail',
    method: 'get',
    params: data
  })
}