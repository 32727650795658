<template>
  <div class="content-info">
    <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700" :text="showText" :is-full-screen="true" />
    <TheNav :navLinkList="navLinkList" :classifyList="classifyList" />
    <div class="info">
      <div class="info-left">
        <img :src="detail.productImgUrl" :title="detail.productName" :alt="detail.productName" />
      </div>
      <div class="info-right info-right-gray">
        <div class="info-content size-12">  <div class="info-title info-title-blue size-18">{{ detail.productName }}</div>{{ detail.productContent }}</div>
      </div>
      <div style="clear:both;"></div>
    </div>
    
    <!-- <div class="ql-snow"><div class="info-detail ql-editor" v-html="detail.productDescribe" >
    </div></div> -->

    <div class="pro-process" v-if="processList && processList.length > 0">
      <div class="block-title center size-20 bold-title">产品加工图</div>
      <div class="text-doru"></div>
      <div class="process-content" v-show="!isShow">
        <!-- <Slider :slides="processList" v-slot="{ currentSlide }"> -->
            <div class="product-slider" v-for="(item,key) in processList" :key="key">
                <img  alt="产品加工" :src="item" class="product-slider-img">
            </div>
            <div style="clear:both;"></div>
        <!-- </Slider> -->
      </div>

       <div class="process-content" v-show="isShow">
        <Slider :slides="processSmList" v-slot="{ currentSlide }">
            <div class="product-slider" v-for="(item,key) in currentSlide" :key="key">
                <img  alt="产品加工" :src="item" class="product-slider-img">
            </div>
            <div style="clear:both;"></div>
        </Slider>
      </div>
    </div>

    <div class="pro-sce" v-if="sceList.length > 0">
      <div class="block-title center size-20 bold-title">应用场景</div>
      <div class="text-doru"></div>
      <div class="sce-content" v-show="!isShow">
        <Slider :slides="sceList" v-slot="{ currentSlide }">
            <div class="sce-item" v-for="(item,key) in currentSlide" :key="key">
                <div class="sce-product-slider"><img  :alt="item.name" :title="item.name" :src="item.imgUrl" class="product-slider-img"></div>
                <div class="product-slider-title bold-title size-12">{{ item.name }}</div>
                <div class="product-slider-desc"><pre>{{ item.sceneContent }}</pre></div>
            </div>
             <div style="clear:both;"></div>
        </Slider>
      </div>

      <div class="sce-content" v-show="isShow">
        <Slider :slides="sceSmList" v-slot="{ currentSlide }">
            <div class="sce-item">
                <div class="sce-product-slider"><img  :alt="currentSlide.name" :title="currentSlide.name" :src="currentSlide.imgUrl" class="product-slider-img"></div>
                <div class="product-slider-title bold-title  size-12">{{ currentSlide.name }}</div>
                <div class="product-slider-desc"><pre>{{ currentSlide.sceneContent }}</pre></div>
            </div>
            <div style="clear:both;"></div>
        </Slider>
      </div>
    </div>

    <div class="more-info">
      <div class="more-title size-20 bold-title">更多产品</div>
      <div class="text-doru"></div>
      <div class="more-info-content">
        <div class="more-item" v-for="(item,key) in moreList" :key="key">
          <router-link :to="{ name:'ProductDetail' , params: { productId: item.id }}">
          <div class="more-item-title size-14">{{ item.productName }}</div>
          <div class="more-info-item" >
            <img :alt="item.productName" :title="item.productName" :src="item.productImgUrl" class="more-item-img">
          </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductNavList } from "@/utils/common";
import { getList,getProductDetail } from "@/api/product/index";

export default {
  name: 'ProductDetail',
  data() {
    return {
      screenWidth:document.body.clientWidth,
      show:false,
      showText:'',
      proParams:{
        productTypeId:''
      },
      params:{
        id:''
      },
      detail:{
      },
      moreList:[],
      slides: [
        {
          title: '产品中心',
          img: require('@/assets/product.png'),
        }
      ],
      classifyList:[],
      navLinkList:[],
      isShow:false,
      processList:[],
      processSmList:[],
      sceList:[],
      sceSmList:[]
    }
  },
  created(){
    this.init()
  },
   mounted() { 
    this.checkLength(this.screenWidth)
    let _this = this
     window.addEventListener("resize", () => { 
      return (() => {
          _this.screenWidth = document.body.clientWidth
      })()
    })
  },
  watch: {
	// 监听路由是否变化
	'$route' (to) { 
		if(to.query.productId != this.params.id){
      this.params.id=to.query.productId;// 把最新id赋值给定义在data中的id
			this.init(); // 重新调用加载数据方法
		}
	},
  screenWidth: function (n) {
      this.checkLength(n)
    }
},
  methods: {
    checkLength(n) {
      // console.log(n)
      if(n <= 1007) {
        this.isShow= true
      }else {
        this.isShow= false
      }
    },
    init() {
      this.params.id = parseInt(this.$route.params.productId)
      this.navLinkList = getProductNavList() 
      this.getProductDeatil()
    },
    getProductDeatil() {
      this.show = true;
      this.showText = "加载中...";
      getProductDetail(this.params)
        .then(res => {
          if (res.code !== 1) {
            console.log(res.msg);
          } else {
            this.detail = res.data
            this.processSmList = []
            this.processList = this.detail.processDescribe && this.detail.processDescribe.length > 0 ? this.detail.processDescribe.split(',') : []
            this.sceList = this.detail.sceneList && this.detail.sceneList.length > 0 ? [this.detail.sceneList] : []
            this.sceSmList = this.detail.sceneList 
            for(let i=0;i<this.processList.length;i=i+2) {
              this.processSmList.push(this.processList.slice(i, i+2))
            }
            let that = this
            this.proParams.productTypeId = res.data.productTypeId
            this.classifyList = []
              getList(this.proParams)
              .then(res => {
                if (res.code !== 1) {
                  console.log(res.msg);
                } else {
                  let info 
                  res.data[0].productList.forEach((item) =>  {
                    info = {
                      name: item.productName,
                      link:{name:'ProductDetail',params:{productId : item.id}}
                    }
                    this.classifyList.push(info)
                  })
                  this.moreList = res.data[0].productList.filter(function(item){return item.id!=that.detail.id}).slice(0, 4)
                }
            this.show = false;
          }).catch(err => {
              console.log(err);
              this.show = false;
            });

            this.navLinkList.push({name:this.detail.productName,link:''})
          }
        })
        .catch(err => {
          console.log(err);
          this.show = false;
        });
    }
  }
}
</script>

<style scoped>
  .content-info {
    /* padding:0 10%; */
  }

  .more-info {
    background-color: #f5f5f5;
    padding:40px 10%;
  }

  .pro-process {
    background-color: #f5f5f5;
    padding:40px 10%;
  }

  .pro-sce {
    padding:40px 10%;
  }

  .info-detail {
    padding:0px 10% 40px 10%;
  }

   .more-title{
    text-align: center;
    /* margin:40px 0px; */
    padding-bottom:10px;
    letter-spacing:4px;
  }

  .more-info-content {
    margin-top:40px;
  }

  .more-item {
    display: inline-block;
    width:23%;
    margin:0px 1%;
    background-color: #fff;
    cursor: pointer;
  }

  .more-item a{
     color: #525252;
     text-decoration: none;
  }

  .more-item a:hover{
     color: #009dff;
  }

  .more-info-item {
    margin:0px 2%;
    /* width:32%; */
    /* max-height:24px; */
    position:relative;
    /* width:21%; */
    height:0;
    padding-top:66.7%;
    margin-bottom:20px;
    /* margin-right:12rpx; */
  }

  .more-item:hover {
    box-shadow: 2px 2px 10px #ccc;
  }

.more-info-item .more-item-img {
    display: inline-block;
    /* width:100%; */
    width:100%;
    height:100%;
    bottom:0;
    left:0;
    position: absolute;
}

 /* .more-item-title {
    margin-top:10px;
    font-size:14px;
    text-align: center;
} */


 .more-item-title {
    text-align: center;
    /* position: absolute;
    top:-40px; */
    width: 100%;
    padding:20px 0;
}

.process-content {
    padding:20px 0px;
  }

  .sce-content {
    padding:20px 0px;
  }

  .product-slider {
    margin:0.2% 0.2%;
    /* width:32%; */
    /* max-height:24px; */
    position:relative;
    width:24.6%;
    height:0;
    padding-top:16.4%;
    float: left;
    /* margin-top:20px; */
    /* margin-right:12rpx; */
  }

  .product-slider .product-slider-img {
    /* width:100%; */
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
  }

  .product-slider-title {
    margin:10px 0;
    text-align: center;
  }

  .product-slider-desc {
    text-align: center;
  }

  .sce-item {
    width: 24%;
    margin:0 0.5%;
    float: left;
  }

  .sce-product-slider {
    /* margin:0 0.5%; */
    /* width:32%; */
    /* max-height:24px; */
    position:relative;
    width:100%;
    height:0;
    padding-top:66.7%;
    display: inline-block;
    /* margin-top:20px; */
    /* margin-right:12rpx; */
  }

  .sce-product-slider .product-slider-img {
    /* width:100%; */
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
  }

@media (max-width: 1024px) {
  .info{
    display: block;
  }

  .info-left {
    display: block;
    width: 100%;
    /* padding-left:20px; */
    margin:0;
  }

  .info-right {
    display: block;
    width: 100%;
    /* margin-top:20px; */
  }

  .more-item {
    width: 46%;
    margin: 0 2%;
    margin-top:4%;
  }

  .product-slider {
    width:49.6%;
    padding-top:33.1%;
  }

  .sce-item {
    width:100%;
    margin:0
  }

  .process-content /deep/ .carousel-indicators {
    bottom:-30px;
  }

  .sce-content /deep/ .carousel-indicators {
    bottom:-30px;
  }
}

@media (max-width: 768px) {
  .info {
    padding:10px;
  }
  
  .info-detail {
    padding:10px;
  }
  .more-info-content {
    margin-top:0;
  }

  .more-info{
    padding:10px;
  }

  .pro-process {
    padding:10px;
  }

  .pro-sce {
    padding:10px;
  }

}
</style>