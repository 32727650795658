/**
 * 关于我们模块类型
 * @returns
 */
 export function getAboutList() {
  const list = [
    {
      name:'关于易宝通',
      link:{ name:'AboutIndex' }
    },
    {
      name:'荣誉资质',
      link:{ name:'AboutHonor' }
    },
    {
      name:'合作伙伴',
      link:{ name:'AboutPartner' }
    },
    {
      name:'联系我们',
      link:{ name:'AboutContact' }
    }
  ]
  return list
}

export function getAboutNavList() {
  const list = [
    {
      name:'首页',
      link:{ name:'Index' }
    },
    {
      name:'关于我们',
      link:{ name:'About' }
    },
  ]
  return list
}

export function getProductNavList() {
  const list = [
    {
      name:'首页',
      link:{ name:'Index' }
    },
    {
      name:'产品中心',
      link:{ name:'ProductList' }
    },
  ]
  return list
}

export function getNewsNavList() {
  const list = [
    {
      name:'首页',
      link:{ name:'Index' }
    },
    {
      name:'新闻中心',
      link:{ name:'NewsList' }
    },
  ]
  return list
}

export function getCaseNavList() {
  const list = [
    {
      name:'首页',
      link:{ name:'Index' }
    },
    {
      name:'成功案例',
      link:{ name:'CaseDetail' }
    },
  ]
  return list
}

